import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { AlertService } from '@app/core/services/ui-components/alerts/alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { IconComponent } from '../button/icon.component';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule, TranslateModule, IconComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  animations: [
    trigger('alert', [
      state('open', style({ transform: 'translateY(0%)', opacity: 1 })),
      state('void', style({ transform: 'translateY(-200%)', opacity: 0 })),
      transition('void => open', [animate('300ms ease-in-out')]),
      transition('open => void', [animate('300ms ease-in-out')]),
    ]),
    trigger('expand', [
      state('collapsed', style({ height: '0px', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class AlertComponent implements OnInit {
  private readonly alertService: AlertService = inject(AlertService);

  alertType$: Observable<string>;
  alertTitle$: Observable<string>;
  alertDescription$: Observable<string>;
  showsAlert$: Observable<boolean>;
  isExpanded$: Observable<boolean>;
  currentAlertType: string = '';

  constructor() {
    this.alertType$ = this.alertService.alertType$;
    this.alertTitle$ = this.alertService.alertTitle$;
    this.alertDescription$ = this.alertService.alertDescription$;
    this.showsAlert$ = this.alertService.showsAlert$;
    this.isExpanded$ = this.alertService.isExpanded$;
  }

  ngOnInit(): void {
    this.alertType$.subscribe((type) => (this.currentAlertType = type));
  }

  toggleExpand(): void {
    this.alertService.toggleExpand();
  }

  closeAlert(): void {
    this.alertService.closeAlert();
  }
}
