import { CanvaOffComponent } from '@app/shared/ui-components/canva-off/canva-off.component';
import { Component, Input, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LoggerService } from '@services/log/logger.service';
import { NavigationService } from '@services/navigation/navigation.service';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [CanvaOffComponent, TranslateModule],
  template: `<app-canva-off></app-canva-off>
  <section class="mt-2">
   <div id="error-container">
  <div id="error">
    <a href="error">Homepage</a>
    <div id="error-text-container">
      <h1 id="txt-error">!</h1>
      <h2 class="text-refleted" data-text="Error">Error</h2>
      <p id="error-text">{{ errorText }}</p>
    </div>
  </div>
</div>
  </section>
  `,
  styles: [`@import '@assets/theme/variables.scss';

  * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
  }

  body {
    padding: 0;
    margin: 0
  }

  #error-container {
    position: relative;
    height: 50vh;
  }

  #error {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 767px;
    width: 100%;
    line-height: 1.4;
    text-align: center
  }

  #error-text-container {
    position: relative;
    height: 260px;
    margin-bottom: 20px;
    z-index: -1
  }
  .text-refleted {
  font-family: montserrat, sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 300px;
  font-size: 64px;
  font-weight: 700;
  color: var(--secondary);
  text-transform: uppercase;
  text-shadow: 0 2px 0 var(--disabled);
  letter-spacing: 13px;
  margin: 0;
}

.text-refleted::after {
  content: attr(data-text); /* Uses the content of the 'data-text' attribute */
  position: absolute;
  left: 0;
  width: 100%;
  height: 80%;
  font-size: 100%; /* Size of the reflection text */
  opacity: 0.3; /* Opacity of the reflection */
  transform-origin: bottom; /* Transformation origin */
  transform: scaleY(-1); /* Vertically flips the reflection */
  z-index: -1; /* Places the reflection behind the text */
}
  #txt-error {
    font-family: montserrat, sans-serif;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 224px;
    font-weight: 900;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -12px;
    color: var(--disabled);
    text-transform: uppercase;
    text-shadow: -1px -4px 0 var(--secondary);
    letter-spacing: -20px
  }


  #error a {
    font-family: montserrat, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    color: var(--terciary);
    text-decoration: none;
    border: 2px solid;
    border-radius: 7px;
    padding: 10px 40px;
    font-size: 14px;
    font-weight: 700;
    -webkit-transition: .2s all;
    transition: .2s all
  }

  #error a:hover {
    color: var(--secondary);
  }


  @media only screen and (max-width:480px) {
    .error .error-text-container h1 {
      font-size: 182px
    }
  }


  `]
})
export class ErrorComponent {
  logger = inject(LoggerService);
  navigationService = inject(NavigationService);
  translateService = inject(TranslateService);
  @Input() errorText: string = "ERROR OCCURED" ;
  private readonly TranslateService = inject(TranslateService);
  constructor () {
    this.logger.warn('ERROR');
    const pageName: string = `${this.translateService.instant('ERROR')}`;
    this.navigationService.setPageName(pageName);
  }
}
