<app-alert></app-alert>
<div class="app-container" [class.navbar-collapsed]="navbarCollapsed">
  @defer() {
  <app-navbar class="app-navbar" (toggle)="toggleNavbar()" [navbarCollapsed]="navbarCollapsed"></app-navbar>

  <app-header class="app-header"></app-header>

  <app-loader>
    <main class="main-content p-2 text-d2l">
      <router-outlet></router-outlet>
    </main>
  </app-loader>
  } @error {
  <app-error errorText="An error occurred"></app-error>
  }

  <app-footer class="app-footer"></app-footer>
</div>
