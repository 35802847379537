import { catchError, of, tap, throwError } from 'rxjs';
import { type HttpHandlerFn, type HttpInterceptorFn, type HttpRequest, HttpResponse, type HttpErrorResponse } from '@angular/common/http';
import { InterceptorLabels } from '@models/enums/interceptors-label.enum';
import { D365GW, GATEWAY_TARGET, TARGET } from '../services/finops/D365-gateway.service';

export const D365GatewayInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  if (req.headers.get(TARGET)?.includes(D365GW)) {
    if ((req.headers.get(GATEWAY_TARGET)?.includes(InterceptorLabels.CRUD_REQUESTS)) ?? false) {
      console.log('D365GatewayInterceptor Request : ' + req.method + ' ' + req.urlWithParams);
      return next(req).pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {

            switch (evt.status) {
              case 200:
              case 201:
              case 204:
                //  The success codes you want to check for
                console.log('D365GatewayInterceptor Request successful, code : ' + evt.status);
                return next(req);

              case 400:
                // Use alert for Unauthorized error messages
                alert('Bad Request');
                // reload the page and stop the execution
                document.location.reload();
                return of(null);

              case 401:
                // Use alert for Unauthorized error messages
                alert('Unauthorized');
                // reload the page and stop the execution
                document.location.reload();
                return of(null);

              default:
                return next(req);

            }

            //do nothing

          } else {
            //it's not an instance of HttpResponse
            return of(null);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMessage ;
          if (error.error instanceof ErrorEvent) {
            // Client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // Server-side error
            errorMessage = `Error ${error.status}: ${error.statusText}`;
          }
          // Use alert for error messages
          alert(errorMessage);
          return throwError(() => error);
        })
      );
    } else {
      return next(req);
    }
  }
  // Check if the request contains the header 'X-Request-Source' with the value 'crud-requests'
  else {
    // If the 'X-Request-Source' header does not contain 'crud-requests', simply pass the request without modifications
    return next(req);
  }
};
