import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@services/navigation/navigation.service';
import { LoggerService } from '@app/core/services/log/logger.service';
import { StringValidator } from 'src/app/shared/utils/validators/string.validator';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private readonly translateService = inject(TranslateService);
  private readonly navigationService = inject(NavigationService);
  private readonly logger = inject(LoggerService);

  private readonly supportedLanguages: string[] = environment.translation.supportedLanguages;

  setLanguageByNavigator (): void {
    let lang = '';
// this.logger.debug(`TranslationService : item : local storage ${localStorage.getItem(LANGUAGE_ITEM_KNAME)}`);
// this.logger.debug(`TranslationService : translate service value ${this.translateService.getBrowserLang()}`);
    const computedLanguage: string | undefined = localStorage.getItem(LANGUAGE_ITEM_KNAME) ?? this.translateService.getBrowserLang();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!StringValidator.isNullOrEmptyString(computedLanguage)) lang = computedLanguage!;
    this.setLangage(lang);
  }

  setLangage (lang: string): void {
// this.logger.debug(`TranslationService : lang ${lang}`);
    const isSupported = this.checkSupport(lang);
// this.logger.debug(`TranslationService : is supported : ${isSupported}`);

    if (!isSupported) lang = this.translateService.getDefaultLang();

    this.navigationService.setUserLang(lang);
    this.translateService.use(lang);
  }

  private checkSupport (lang: string): boolean {
    return this.supportedLanguages.includes(lang);
  }
}
export const LANGUAGE_ITEM_KNAME = 'language';
