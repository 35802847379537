<div
  *ngIf="showsAlert$ | async"
  [@alert]="(showsAlert$ | async) ? 'open' : 'close'"
  [ngClass]="{
    success: currentAlertType === 'success',
    warning: currentAlertType === 'warning',
    danger: currentAlertType === 'danger',
    info: currentAlertType === 'info'
  }"
  class="alert-class"
>
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <div class="pe-3">
        <app-icon class="fa-lg" icon="circle-info" package="solid"></app-icon>
      </div>
      <div>
        {{ alertTitle$ | async }}
      </div>
    </div>

    <div class="d-flex align-items-center">
      @if (alertDescription$ | async) {
      <button
        class="d-flex align-items-center bttn-icon-info expand-icon"
        (click)="toggleExpand()"
      >
        @if (isExpanded$ | async) {
        <span>{{ "GL.MSG.HideDetails" | translate }}</span>
        } @else {
        <span>{{ "GL.MSG.ShowDetails" | translate }}</span>
        }
      </button>
      <div class="separator-vr ms-2 me-1"></div>
      }
      <button
        class="d-flex align-items-center bttn-icon-info"
        (click)="closeAlert()"
      >
        <app-icon class="fa-lg" icon="xmark" package="solid"></app-icon>
      </button>
    </div>
  </div>
  @if (alertDescription$ | async) {
  <div
    [@expand]="(isExpanded$ | async) ? 'expanded' : 'collapsed'"
    [ngClass]="{ 'expanded-description': (isExpanded$ | async) }"
  >
    {{ alertDescription$ | async }}
  </div>
  }
</div>
