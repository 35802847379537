import { FoInventParameterService } from './core/services/finops/client-api/fo-invent-parameter.service';
import {
  Component,
  type ElementRef,
  Input,
  type OnInit,
  ViewChild,
  inject,
  DestroyRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslationService } from '@core/services/translation/translation.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { LoggerService } from '@core/services/log/logger.service';
import { environment } from '@environments/environment';
import { HeaderComponent } from '@core/layout/header/header.component';
import { NavbarComponent } from '@core/layout/navbar/navbar.component';
import { FooterComponent } from '@core/layout/footer/footer.component';
import { Subscription } from 'rxjs';
import { ThemeService } from './core/services/utilities/theme.service';
import { AlertComponent } from './shared/ui-components/alert/alert.component';
import { ErrorComponent } from './core/layout/error.component';
import { LoaderComponent } from './shared/ui-components/loader/loader/loader.component';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { LoginService } from './core/services/login/login.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    HeaderComponent,
    FooterComponent,
    NavbarComponent,
    AlertComponent,
    ErrorComponent,
    LoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  @Input() navbarCollapsed: boolean = false;
  @ViewChild('navbar') navbar!: ElementRef;
  loginService: LoginService = inject(LoginService);
  private readonly translationService: TranslationService = inject(TranslationService);
  private readonly logger: LoggerService = inject(LoggerService);
  private readonly themeService: ThemeService = inject(ThemeService);
  private subs: Subscription = new Subscription();
  private readonly FoInventParameterService: FoInventParameterService = inject(FoInventParameterService);


  isDarkTheme!: boolean;
  destroyRef: DestroyRef = inject(DestroyRef);
  keycloakService: KeycloakService = inject(KeycloakService);
  userProfile: KeycloakProfile | null = null;
  navigationService: NavigationService = inject(NavigationService);


  async ngOnInit(): Promise<void> {
    this.logger.info(`AppComponent env : ${environment.name}`);
    this.translationService.setLanguageByNavigator();

  await this.loginService.updateLogin();
  // console.log('JDEBUG : AppComponent ngOnInit After update login',this.navigationService.getUserRoles());
  this.applyNavbarState();
    this.subs.add(
      this.themeService.isDarkTheme$.subscribe((isDarkTheme: boolean) => {
        this.isDarkTheme = isDarkTheme;
        this.applyTheme();
      })
    );
  }

  private applyTheme() {
    document.body.classList.toggle('dark-theme', this.isDarkTheme);
  }

  toggleNavbar(): void {
    this.navbarCollapsed = !this.navbarCollapsed;
    this.applyNavbarState();
  }

  applyNavbarState(): void {
    const appContainer = document.querySelector('.app-container');
    if (this.navbarCollapsed) {
      appContainer?.classList.add('navbar-collapsed');
    } else {
      appContainer?.classList.remove('navbar-collapsed');
    }
  }
}
